<template>
  <v-container >
    <v-row class="mx-0">
      <v-col class="text-center ">
          <BannerImage v-if="(content && content.pages && content.pages[0].image)" :title="content.pages[0].title" :subtitle="content.pages[0].subtitle" :img="content.pages[0].image"/>
          <BannerImage v-else-if="(content && content.pages )" :title="content.pages[0].title" :subtitle="content.pages[0].subtitle" :img="content.main_img"/>
      </v-col>
    </v-row>
    <div v-if="(content && content.pages)">
    <template v-if="content.pages[0].section_style == 'slider'">
        <v-card rounded="0" flat  style="z-index:0;" >
            <v-tabs
            rounded="0"
            app
            background-color="grey lighten-3"
            centered
            v-model="tab"
            icons-and-text
            center-active
            show-arrows
            grow
            
            >
                <v-tabs-slider></v-tabs-slider>
                <v-tab 
                  v-for="(item, i) in content.pages[0].sections"  
                  :key="i"
                  :href="`#tab-${i}`"
                  style="font-size:10px;"
                >
                    {{item.title}}
                    <v-icon size="18">{{item.icon}}</v-icon>
                </v-tab>

            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item
                    v-for="(tab, i) in content.pages[0].sections"
                    :key="i"
                    :id="`tab-${i}`"
                    :value="`tab-${i}`"
                    eager
                >
                    <v-card flat>
                      <v-card-text>
                        <RenderHtml :html="tab.content" />
                      </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </template>
    <template  v-else-if="content.pages[0].section_style == 'list'">
      <v-card rounded="0" flat class="pa-2" style="z-index:0;" >
           <v-expansion-panels
                accordion
                focusable
                
                v-model="panel"
                >
                <v-expansion-panel              
                    v-for="(tab, i) in content.pages[0].sections"
                    :key="i"
                    :value="`panel-${i}`"
                >
                    <v-expansion-panel-header class="primary--text" ripple color="grey lighten-5">
                        <div style="display: flex;align-items: center;">
                            <v-icon color="primary" class="mr-4" >{{tab.icon}}</v-icon>
                            <span>{{tab.title}}</span>
                        </div>
                        
                    </v-expansion-panel-header>
                    <v-expansion-panel-content  class="py-5">
                      <RenderHtml :html="tab.content" />
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>
    </template>
    <template  v-else>
      <v-container>
        <v-row dense class="mx-0" v-for="(tab, i) in content.pages[0].sections" :key="i">
          <RenderHtml :html="tab.content" />        
        </v-row>
      </v-container>
    </template>
    </div>
  </v-container>

</template>

<script>
// @ is an alias to /src
import RenderHtml from "@/components/RenderHtml.vue";
import BannerImage from '@/components/BannerImage.vue'

import axios from 'axios'

export default {
  name: 'stay',
  props: ['etab','page'],
  components: {
    BannerImage,
    RenderHtml
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  beforeRouteEnter (to, from, next) {
    axios.get('/etab/' + to.params.etab + '/' + to.params.page)
          .then((response) => {
            next(vm => vm.setData(null,response.data))
          })
          .catch((err) => {
            next(vm => vm.setData(err, null))
          })
    
  }, 
  // when route changes and this component is already rendered,
  // the logic will be slightly different.
  beforeRouteUpdate (to, from, next) {
    this.content = null
    axios.get('/etab/' + to.params.etab + '/' + to.params.page)
      .then((response) => {
        this.setData(null,response.data)
        next()
      })
      .catch((err) => {
        this.setData(err, null)
        next()
      })
  },
  data () {
    return {
      tab: null,
      panel: false,
      loading: false,
      error: null,
      content: null
    }
  },
  methods: {
    async fetchData () {
      this.error = this.content = null
      this.loading = true
      const response = await axios.get('/etab/' + this.etab + '/' + this.page)
      this.content =  response.data
      this.loading = false
      document.title = `Alpine Resorts - Room Directory - ` + this.content.name + ` - ` + this.content.pages[0].title
    },
    setData (err, content) {
      if (err) {
        this.error = err.toString()
      } else {
        this.content = content
        document.title = `Alpine Resorts - Room Directory - ` + this.content.name + ` - ` + this.content.pages[0].title
      }
    },

  }
}
</script>
<style>
table {
  width:100% !important;
}
table thead {
  background: var(--color-primary);
  color:#fff;
  
}
table thead th {
  text-align: left;
}
table tbody tr:nth-child(odd){
  background: #f5f5f5;
}
table th, table td{
  padding:0 1rem;
}
img.fr-dib.fr-fir {
    margin-right: 0;
}
img.fr-dib {
    margin: 5px auto;
    display: block;
    float: none;
    vertical-align: top;
}
img.fr-dib.fr-fil {
    margin-left: 0;
}
</style>
